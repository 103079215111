<template>
  <v-layout column style="width: 100%">
    <DataTable
      class="mx-4"
      :headers="headers"
      :pagination.sync="pagination"
      :objects="items"
      :loading="loading"
      noDataText="Brak miejsc"
      hideToolBar
    >
      <template v-slot:cell="{ header, objects }">
        <component
          :is="cellFactory(header, objects)"
          :value="cellValueFactory(header, objects)"
          :header="header"
          :item="objects.item"
          @remove="(item) => $emit('remove', item)"
          @edit="(item) => $emit('edit', item)"
        ></component>
      </template>
    </DataTable>
  </v-layout>
</template>

<script>
export default {
  props: {
    items: {},
  },
  data() {
    return {
      types: {
        F: "Praktyka fizjoterapeutyczna",
        L: "Praktyka lekarska",
        O: "Podmiot leczniczy",
        Z: "Zakład",
        J: "Jednostka",
        K: "Komórka",
        I: "Instytucja",
        P: "Praktyka pielęgniarska",
      },
      headers: [
        {
          text: "Gabinet",
          align: "left",
          sortable: false,
          value: "office",
        },
        {
          text: "Typ",
          align: "left",
          sortable: true,
          value: "organization_cell",
        },
        {
          text: "Identyfikacja",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Inne",
          align: "right",
          sortable: false,
        },
      ],
      pagination: {
        rowsPerPage: -1,
      },
    };
  },
  components: {
    DataTable: () => import("@/components/tables/DataTable"),
    PreWrapCell: () => import("@/components/tables/PreWrapCell"),
    DefaultCell: () => import("@/components/tables/DefaultCell"),
    TableEditButtonsCell: () =>
      import("@/components/tables/TableEditButtonsCell"),
  },
  methods: {
    cellFactory(header) {
      switch (header.text) {
        case "Inne":
          return "TableEditButtonsCell";
        case "Identyfikacja":
          return "PreWrapCell";
        default:
          return "DefaultCell";
      }
    },
    cellValueFactory(header, objects) {
      switch (header.text) {
        case "Gabinet": {
          const office = objects.item[header.value];
          return office ? office.name : 'Wszystkie';
        }
        case "Typ":
          return this.types[objects.item[header.value]];
        case "Identyfikacja": {
          const row = objects.item;
          let id = "";
          if (row.rpwdl) {
            id += `RPWDL: ${row.rpwdl}\r\n`;
          }
          if (row.departmental_code) {
            id += `Kod resortowy: ${row.departmental_code}\n`;
          }
          if (row.regon) {
            id += `REGON: ${row.regon}\n`;
          }
          if (row.dist_chamb_num) {
            id += `Numer izby okręgowej: ${row.dist_chamb_num}\n`;
          }
          if (row.teryt) {
            id += `TERYT: ${row.teryt}\n`;
          }
          return id;
        }
        case "Adres": {
          const row = objects.item;
          let address = "";
          if (row.address) {
            address += `${row.address}`;
          }
          if (row.zipcode) {
            address += `, ${row.zipcode}`;
          }
          if (row.city) {
            address += ` ${row.city}`;
          }
          return address;
        }
        case "Inne":
          return "";
        default:
          return objects.item[header.value];
      }
    },
  },
};
</script>